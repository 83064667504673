<template>
    <loading v-if="isHttpData" style="margin-top: 210px"></loading>
    <div v-else class="successful" id="scroll" :style="{'--themecolor':themecolors,'--rgbathemecolor':hexToRgba}">
        <div style="min-height:84%">
            <div class="outer flex-center center">
                <div class="core flex-center center">
                    <van-icon name="success" />
                </div>
            </div>
            <p>预订成功！</p>
            <div class="code flex-center center">
                <img src="@/assets/icon/code.svg" alt="">
            </div>
            <div class="tear-bg">
                <p v-if="hotel_reservation_number != ''">凭证号：{{hotel_reservation_number}}</p>
                <img src="@/assets/icon/tear.png" alt="">
            </div>
            <button class="flex-center center" @click="check_hotel_user_info">查看订单</button>
            <p class="p-color" @click="del_payment_voucher">信息有误，注销凭证</p>
        </div>
        <Support></Support>
        <van-dialog class="vant-fill-dialog" v-model="fillIndia" :show-confirm-button='false'>
            <div class="header flex-center between"><span></span>订单信息<van-icon name="cross" @click="fillIndia = false"/></div>
            <div class="user-info">
                <h2>{{fill_info.room_title}}</h2>
                <p>{{fill_info.starting_time}} ~ {{fill_info.end_time}}  共{{computation(fill_info.starting_time,fill_info.end_time)}}晚</p>
                <h2>入住信息</h2>
                <van-field v-model="fill_info.user_name" name="姓名" label="姓名" disabled/>
                <van-field v-model="fill_info.tow_user_name" v-if="fill_info.living_population != 1" name="同行人姓名" label="同行人姓名" disabled/>
                <van-field v-model="fill_info.mobile" name="手机号" label="手机号" disabled/>
                <van-field :value="fill_info.living_population+'人'" name="入住人数" label="入住人数" disabled/>
                <van-field v-model="fill_info.beizhu" name="备注" label="备注" disabled/>
            </div>
        </van-dialog>
        <sideBar></sideBar>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { hexToRgba } from "@/js/until.js";
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import Loading from "@/components/loading/loading";
export default {
    data(){
        return{
            site_id:"",
            fillIndia:false,
            hotel_reservation_number:"",
            id:"",
            fill_info:{},
            isHttpData: true,
            module_id:''
        }
    },
    components:{
        sideBar,
        Support,
        Loading
    },
    computed: {
        ...mapState(['themecolors']),
        hexToRgba(){
            return hexToRgba( this.themecolors, 0.2 )
        }
    },
    async created(){
        this.site_id = this.$route.query.site_id;
        this.module_id = this.$route.query.module_id
        // console.log(this.module_id);
        this.get_hotel_voucher_number();
    },
    methods:{
        async get_hotel_voucher_number(){
            const res = await this.$store.dispatch('get_hotel_voucher_number',{site_id: this.site_id,module_id:this.module_id});
            if(res.data.code == 200){
                this.isHttpData = false
                this.hotel_reservation_number = res.data.data.module_content.hotel_reservation_number;
                this.id = res.data.data.module_content.id;
            }
        },
        computation(sDate1, sDate2){
            return parseInt(Math.abs(Date.parse( new Date(sDate1))  - Date.parse( new Date(sDate2))) / 1000 / 60 / 60 /24)  
        },
        async check_hotel_user_info(){
            this.fillIndia = true;
            const res = await this.$store.dispatch("check_hotel_user_info",{id: this.id});
            if(res.data.code == 200){
                this.fill_info = res.data.data;
            }
        },
        //注销凭证
        async del_payment_voucher(){
            let that = this
            async function beforeClose(action, done) {
                if (action === 'confirm') {
                    const res = await that.$store.dispatch('del_hotel_user_info', {
                       id: that.id 
                    })
                    if(res.data.code == 200){
                        that.$toast(res.data.message)
                        that.$router.push({
                            "path": "/hotel",
                            "query": {
                                "site_id": that.$route.query.site_id,
                                "page_id": sessionStorage.getItem('page_id'),
                                "module_id": sessionStorage.getItem('module_id')
                            }
                        })
                        done();
                    }else{
                        that.$toast(res.data.message)
                    }
                } else {
                    done();
                }
            }
            
            this.$dialog.confirm({
              title: '温馨提示',
              message: '是否确认注销凭证，重新生成？',
              beforeClose,
            });
            
        }
    }
}
</script>

<style lang='less' scoped>
@Color:var(--themecolor);
@rgbathemecolor:var(--rgbathemecolor);

.flex-center{
    display: flex;
    align-items: center;
}
.center{
    justify-content: center;
}
.between{
    justify-content: space-between;
}
.successful{
    text-align: center;
    .p-color{
        margin-top: 50px;
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #00CCB0;
    }
    .outer{
        width: 1.1rem;
        height: 1.1rem;
        background: #e1f8f5;
        border-radius: 50%;
        margin: 15% auto .2rem;
    }
    .core{
        width: .8rem;
        height: .8rem;
        background: #00ccb0;
        border-radius: 50%;
        border: 2px solid #fff;
        color: #fff;
        font-size: .4rem;
    }
    p{
        font-size: .18rem;
        font-weight: bold;
    }
    .code{
        width: 1.6rem;
        height: .6rem;
        margin: .4rem auto -0.2rem;
        img{
            height: 100%;
        }
    }
    .tear-bg{
        margin: 0 auto;
        height: 1.5rem;
        overflow: hidden;
        position: relative;
        img{
            width:90%;
            height: 90%;
            position: absolute;
            top: 0;
            left: 7%;
        }
        text-align: left;
        p{
            position: absolute;
            font-weight: normal;
            z-index: 2;
            top: .7rem;
            left: 0;
            width: 100%;
            font-size: 14px;
            color: #333333;
            text-align: center;
        }
    }
    button{
        width: 1rem;
        height: .4rem;
        background: #00ccb0;
        border-radius: 55px;
        border: none;
        color: #fff;
        font-size: .14rem;
        margin: .2rem auto;
    }
    .vant-fill-dialog{
        padding: 0;
        text-align: left;
        .header{
            padding: 0 .2rem;
            justify-content: space-between;
            height: .5rem;
            background: linear-gradient(90deg, #8CEDE5 0%, #01CCB0 100%);
            color: #fff;
        }
        .user-info{
            justify-content: center;
            padding: .1rem .2rem .2rem;
            line-height: .3rem;
            h2{
                padding: 0 .14rem;
                font-size: .15rem;
            }
            p{
                padding: 0 .14rem;
                font-size: .14rem;
                margin: 0;
            }
            /deep/.van-cell__title{
                color: #66656A;
            }
            /deep/.van-field__control:disabled{
                color: #262739;
                -webkit-text-fill-color: #262739;
            }
        }
    }
}
</style>